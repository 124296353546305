import React from 'react'
import { FaCaretRight } from 'react-icons/fa'

// styled in index.js since used in multiple places
export default props => (
  <button className={`cta ${props.styleClass}`}>
    <span>{props.children}</span>
    <span>
      <FaCaretRight />
    </span>
  </button>
)
