import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'
import Button from '../components/button'

export default ({ data }) => {
  const teamMember = data.markdownRemark
  const image = data.teamImages.edges.find(
    edge => edge.node.name === teamMember.frontmatter.graphqlKey
  )
  const fluid = image.node.childImageSharp.fluid
  return (
    <Layout>
      <div className="team-member-page">
        <Container>
          <Row>
            <Col xs={12} md={{ size: 6 }}>
              {<Img alt="" fluid={fluid} />}
            </Col>
            <Col xs={12} md={{ size: 5, offset: 1 }}>
              <h2>{teamMember.frontmatter.name}</h2>
              <h4>{teamMember.frontmatter.position}</h4>
              <div dangerouslySetInnerHTML={{ __html: teamMember.html }} />
              <Link to="/team">
                <Button>Meet Our Team</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        graphqlKey
        name
        position
        email
        phone
      }
    }
    teamImages: allFile(
      sort: { order: ASC, fields: [absolutePath] }
      filter: { relativePath: { regex: "/team/.*.jpg/" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
